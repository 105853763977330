import { CommonModule } from '@angular/common';
import { Component, OnInit, Provider } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslationService } from './core/services/translation.service';
import { MainComponent } from './shared/components/layouts/main/main.component';
import { RestModule } from './core/rest/rest.module';
import { ErrorStateMatcher } from "@angular/material/core";
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, filter, map, take, throwError } from 'rxjs';
import { GoogleTagManagerService } from './core/rest/google-tag-manager/google-tag-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerRestService } from './core/rest/customer/customer-rest.service';
import { SnackBarService } from './core/services/snackbar.service';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && control.touched)
  }
}

export const ERROR_STATE_MATCHER_PROVIDER: Provider = {
  provide: ErrorStateMatcher,
  useValue: new MyErrorStateMatcher(),
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MainComponent,
    RestModule,
    MatIcon
  ],
  providers: [
    ERROR_STATE_MATCHER_PROVIDER
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isTouchDevice = false;

  constructor(_translate: TranslationService,
              private iconRegistry: MatIconRegistry,
              private translationService: TranslationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private domSanitizer: DomSanitizer,
              private googleTagManagerService: GoogleTagManagerService,
              private customerRestService: CustomerRestService,
              private snackBarService: SnackBarService) {
    iconRegistry.addSvgIconSetInNamespace(
      'svg',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons.svg')
    );
  }

  ngOnInit(): void {
    this.checkTouchDevice();

    this.activatedRoute.queryParams
      .pipe(
        map(params => params['lang']),
        filter(lang => lang),
        take(1),
      )
      .subscribe(lang => {
        this.translationService.setLanguage(lang, false);
        this.router.navigate([], {queryParams: {}})
      });

    this.googleTagManagerService.getGoogleTag()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const message = this.customerRestService.getErrorMessage(err);
          this.snackBarService.showErrorMessage(message);
          return throwError(() => err);
        })
      ).subscribe((googleTagContainer) => {
        const googleTag = googleTagContainer.googleTag;
        if (googleTag) {
          this.injectGoogleTagManagerScript(googleTag);
          this.injectGoogleTagManagerNoScript(googleTag);
        }
      })
    this.collectDataFromGoogleTagManager();
  }

  collectDataFromGoogleTagManager() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleTagManagerService.logPageView(event.url);
      }
    });
  }

  checkTouchDevice() {
    this.isTouchDevice = 'ontouchstart' in window;

    if (this.isTouchDevice) {
      const rootElement = document.documentElement;
      rootElement.classList.add('is-touch-device');
    }
  }

  private injectGoogleTagManagerScript(googleTag: string): void {
    const scriptElement = document.createElement('script');
    const scriptElementDataLayer = document.createElement('script');
    scriptElementDataLayer.innerHTML = `window.dataLayer = window.dataLayer || [];`;
    scriptElement.innerHTML = `
      (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push(
        {'gtm.start': new Date().getTime(), event: 'gtm.js'}
      );
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${googleTag}')
    `;
    document.head.appendChild(scriptElementDataLayer);
    document.head.appendChild(scriptElement);
  }

  private injectGoogleTagManagerNoScript(googleTag: string): void {
    const noscriptElement = document.createElement('noscript');
    noscriptElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTag}"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscriptElement);
  }
}
