import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfiguration } from '../../../app-configuration';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  private httpClient = inject(HttpClient);
  private appConfiguration = inject(AppConfiguration);
  controllerUrl = this.appConfiguration.serverApiUrl;

  getGoogleTag(): Observable<{ googleTag: string }> {
    return this.httpClient.get<{ googleTag: string }>(this.controllerUrl + '/shop-settings');
  }

  logPageView(url: string): void {
    const pageViewObject = {
      event: 'pageView',
      pageName: url
    };
    this.addObjectToDataLayer(pageViewObject);
  }

  private addObjectToDataLayer(object: {event: string, pageName: string}): void {
    if (object) window.dataLayer.push(object);
  }
}
